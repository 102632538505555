
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Multiselect from '@vueform/multiselect';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'driver-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedVehicle = ref<string>('');
      const selectedDriver = ref<string>('');
      const selectedHubs = ref<string>('');
      const selectedArea = ref<Array<string>>([]);
      const selectedOffice = ref<Array<string>>([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const driverSchema = Yup.object().shape({
        hubId: Yup.string()
          .required(() => translate('HUBS_IS_REQUIRED_FIELD'))
          .label('typeId'),
        typeId: Yup.string()
          .required(() => translate('TYPE_IS_REQUIRED_FIELD'))
          .label('typeId'),
        vehicleId: Yup.string()
          .required(() => translate('VEHICLEID_IS_REQUIRED_FIELD'))
          .label('vehicleId'),
        mobile: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Mobile'),
        fullName: Yup.string()
          .required(() => translate('FULL_NAME_IS_REQUIRED_FIELD'))
          .label('Full Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
      });

      const onSubmitCreate = async (values) => {
        values.mobile = String('964' + values.mobile);

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        let logoFormData: FormData | null = null;
        if (values.logo) {
          logoFormData = new FormData();
          logoFormData.append('images', values.logo);
        }

        let logoFormDataTow: FormData | null = null;
        if (values.image) {
          logoFormDataTow = new FormData();
          logoFormDataTow.append('images', values.image);
        }
        delete values.logo;
        delete values.image;

        values.areasIds = selectedArea.value;
        values.officesIds = selectedOffice.value;
        const driver = await store.dispatch(Actions.CREATE_DRIVER, values);

        if (driver?.id && logoFormData) {
          await store.dispatch(Actions.UPLOAD_DRIVER_IMAGE, {
            data: logoFormData,
            driverId: driver.id,
          });
        }
        if (driver?.id && logoFormDataTow) {
          await store.dispatch(Actions.UPLOAD_LICENSE_IMAGE, {
            data: logoFormDataTow,
            driverId: driver.id,
          });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_DRIVER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'drivers-listing' });
          });
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('driverCreating'), [
          translate('assets'),
        ]);

        await store.dispatch(Actions.GET_ALL_VEHICLES);
        await store.dispatch(Actions.GET_ALL_DRIVER_TYPES);
        await store.dispatch(Actions.GET_ALL_AREAS, {
          driverId: '',
        });
        await store.dispatch(Actions.GET_ALL_HUBS_CREATING);
        await store.dispatch(Actions.GET_ALL_OFFICES, 'all');
      });

      const offices = computed(() => store.getters.allOfficesList);
      const officesTitle = () => {
        return offices.value.map((r) => {
          return { title: r.titleTranslate, id: r.id };
        });
      };

      const areas = computed(() => store.getters.allAreasList);
      const areasTitle = () => {
        return areas.value.map((i) => {
          return { title: i.titleTranslate, id: i.id };
        });
      };
      return {
        vehicles: computed(() => store.getters.vehicleList),
        driverTypes: computed(() => store.getters.driverTypesList),
        hubs: computed(() => store.getters.hubsList),
        onSubmitCreate,
        areas,
        offices,
        selectedOffice,
        areasTitle,
        submitButton,
        selectedArea,
        translate,
        officesTitle,
        driverSchema,
        goBack,
        selectedVehicle,
        selectedDriver,
        selectedHubs,
        can,
      };
    },
  });
